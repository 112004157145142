import React from 'react';

const ViewJobSkeleton: React.FC = () => {
    return (
        <div className="animate-pulse p-6 max-w-4xl mx-auto">
            <div className="h-8 bg-gray-300 rounded w-1/2 mb-4"></div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
                <div className="bg-gray-300 h-24 rounded-lg"></div>
                <div className="bg-gray-300 h-24 rounded-lg"></div>
            </div>
            <div className="bg-gray-300 h-40 rounded-lg mb-6"></div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
                <div className="bg-gray-300 h-10 rounded-lg"></div>
                <div className="bg-gray-300 h-10 rounded-lg"></div>
            </div>
            <div className="bg-gray-300 h-40 rounded-lg mb-6"></div>
        </div>
    );
};

export default ViewJobSkeleton;
