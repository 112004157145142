import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Layout from './layouts/Layout';
import Home from './pages/Home';
import Analytics from './pages/Analytics';
import Settings from './pages/Settings';
import {I18nProvider} from "./i18n/I18nContext";
import {AuthProvider} from "./auth/AuthContext";
import PrivateRoute from "./auth/PrivateRoutes";
import Jobs from "./pages/jobs/Jobs";
import TranslationAssistant from "./pages/TranslationAssistant";
import ImproveTheAI from "./pages/ImproveTheAI";
import SignIn from "./pages/SignIn";
import JobInit from "./pages/jobs/JobInit";
import JobAuditTranslations from "./pages/jobs/JobAuditTranslations";
import ViewJob from "./pages/jobs/ViewJob";
import GeneratePDF from "./pages/jobs/JobGeneratePdf";
import ViewAllUserJobs from "./pages/jobs/ViewAllUserJobs";

const App: React.FC = () => {
    return (
        <Router>
            <I18nProvider>
                <AuthProvider>
                    <Routes>
                        <Route path="/" element={<PrivateRoute><Layout/></PrivateRoute>}>
                            <Route index element={<Home/>}/>
                            <Route path="analytics" element={<Analytics/>}/>
                            <Route path="settings" element={<Settings/>}/>
                            <Route path="jobs" element={<Jobs/>}/>
                            <Route path="jobs/init" element={<JobInit/>}/>
                            <Route path="/jobs/audit/:jobId" element={<JobAuditTranslations/>}/>
                            <Route path="/jobs/view/:jobId" element={<ViewJob/>}/>
                            <Route path="/jobs/generate-pdf/:jobId" element={<GeneratePDF/>}/>
                            <Route path="/jobs/view-all-user-jobs" element={<ViewAllUserJobs/>}/>
                            <Route path="translation-assistant" element={<TranslationAssistant/>}/>
                            <Route path="improve-the-ai" element={<ImproveTheAI/>}/>
                        </Route>
                        <Route path="/sign-in" element={<SignIn/>}/>
                    </Routes>
                </AuthProvider>
            </I18nProvider>
        </Router>
    );
};

export default App;
