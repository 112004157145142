import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {config} from "../config";
import {useI18n} from "../i18n/I18nContext";

interface Message {
    sender: 'user' | 'ai';
    text: string;
}

const TranslationAssistant: React.FC = () => {
    const { translations } = useI18n();
    const [messages, setMessages] = useState<Message[]>([]);
    const [inputText, setInputText] = useState('');
    const [translationMethod, setTranslationMethod] = useState<'e-c' | 'c-e'>('e-c');
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const welcomeMessage: Message = {
            sender: 'ai',
            text: `${translations.aiTranslationAssistant.initialMessage}`,
        };
        setMessages([welcomeMessage]);
    }, [translations.aiTranslationAssistant.initialMessage]);
    const handleSubmit = async () => {
        if (!inputText.trim()) return;

        const authToken = localStorage.getItem('authToken');
        if (!authToken) {
            const errorMessage: Message = { sender: 'ai', text: 'Authorization token is missing. Please log in.' };
            setMessages((prevMessages) => [...prevMessages, errorMessage]);
            return;
        }

        // Add user message to chat
        const userMessage: Message = { sender: 'user', text: inputText };
        setMessages((prevMessages) => [...prevMessages, userMessage]);

        setLoading(true);

        try {
            // Call the translation API with authorization token
            const response = await axios.post(
                `${config.API_BASE_URL}/api/translate/`,
                {
                    texts: [inputText],
                    translation_method: translationMethod,
                },
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );

            const aiMessages = response.data.responses.map((item:any) => {
                return {
                    sender: 'ai' as const,
                    text: `${item.translation}`,
                };
            });

            setMessages((prevMessages) => [...prevMessages, ...aiMessages]);
        } catch (error) {
            const errorMessage: Message = { sender: 'ai', text: translations.aiTranslationAssistant.error_message };
            setMessages((prevMessages) => [...prevMessages, errorMessage]);
        } finally {
            setLoading(false);
            setInputText(''); // Clear input after submitting
        }
    };

    const handleToggleMethod = () => {
        setTranslationMethod((prevMethod) => (prevMethod === 'e-c' ? 'c-e' : 'e-c'));
    };

    return (
        <div className="flex flex-col h-screen w-9/12 mx-auto">
            {/* Chat History */}
            <div className="flex-1 overflow-y-auto p-4 bg-gray-100">
                {messages.map((message, index) => (
                    <div
                        key={index}
                        className={`flex ${message.sender === 'user' ? 'justify-end' : 'justify-start'} mb-4`}
                    >
                        <div
                            className={`max-w-xs p-3 rounded-lg ${
                                message.sender === 'user' ? 'bg-primary text-white' : 'bg-gray-300 text-gray-900'
                            }`}
                        >
                            {message.text.split('\n').map((line, i) => (
                                <p key={i}>{line}</p>
                            ))}
                        </div>
                    </div>
                ))}
                {loading && (
                    <div className="flex justify-start mb-4">
                        <div className="max-w-xs p-3 rounded-lg bg-gray-300">
                            <div className="flex space-x-2">
                                <div className="w-2.5 h-2.5 bg-gray-700 rounded-full animate-bounce"></div>
                                <div className="w-2.5 h-2.5 bg-gray-700 rounded-full animate-bounce delay-75"></div>
                                <div className="w-2.5 h-2.5 bg-gray-700 rounded-full animate-bounce delay-150"></div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {/* Input Area */}
            <div className="flex  w-full items-center p-4 border rounded-lg bg-white absolute left-0 bottom-0 md:left-1/3 md:-ml-4 md:w-7/12 2xl:ml-4 2xl:left-1/4 2xl:w-2/3">
                <button
                    onClick={handleToggleMethod}
                    className="mr-2 px-1 py-2  text-xs md:text-lg md:py-2 border rounded-lg text-gray-700 bg-gray-200 hover:bg-gray-300"
                >
                    {translationMethod === 'e-c' ? 'E-C' : 'C-E'}
                </button>
                <input
                    type="text"
                    value={inputText}
                    onChange={(e) => setInputText(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && !loading && handleSubmit()}
                    className="flex-1 p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                    placeholder={translations.aiTranslationAssistant.placeholder}
                    disabled={loading}
                />
                <button
                    onClick={handleSubmit}
                    className="ml-4 p-2 bg-primary text-white rounded-lg hover:bg-blue-600"
                    disabled={loading}
                >
                    {translations.aiTranslationAssistant.button}
                </button>
            </div>
        </div>
    );
};

export default TranslationAssistant;
