import React, {useEffect, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import axios from 'axios';
import {useAuth} from '../../auth/AuthContext';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import JobGeneratingLoadingScreen from './JobGeneratingLoadingScreen';
import {useI18n} from '../../i18n/I18nContext';

const GeneratePDF: React.FC = () => {
    const {jobId} = useParams<{
        jobId: string
    }>();
    const {user} = useAuth();
    const navigate = useNavigate();
    const {translations} = useI18n();

    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const generatePDF = async () => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/pdf/generate_translated_pdf`, {
                    user_id: user?.id,
                    job_id: jobId,
                }, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    },
                    responseType: 'blob',
                });

                if (response.status === 200) {
                    const pdfBlob = new Blob([response.data], {type: 'application/pdf'});
                    const pdfUrl = window.URL.createObjectURL(pdfBlob);
                    window.open(pdfUrl, '_blank');

                    // Navigate back to /jobs with success toast
                    navigate('/jobs', {state: {success: translations.generatePDF.successMessage}});
                } else {
                    toast.error(translations.generatePDF.errorMessage);
                }
            } catch (error) {
                console.error('Error generating PDF:', error);
                toast.error(translations.generatePDF.errorMessage);
            } finally {
                setLoading(false);
            }
        };

        generatePDF();
    }, [jobId, user?.id, navigate, translations]);

    if (loading) {
        return <JobGeneratingLoadingScreen message={translations.generatePDF.loadingMessage}/>;
    }
    return (
        <div className="bg-white shadow-md rounded-lg p-6">
            <ToastContainer position="top-right" autoClose={1000}/>
        </div>
    )
};

export default GeneratePDF;
