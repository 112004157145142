import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import MobileNavbar from "../components/Navigation/MobileNavbar";
import Sidebar from "../components/Navigation/Sidebar";
import {useAuth} from "../auth/AuthContext";
import LoadingScreen from "../components/LoadingScreen";

const Layout: React.FC = () => {
    const { loading } = useAuth();
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    return (
        <div className="h-screen flex flex-col bg-gray-100">
            {/* Mobile Navbar */}
            <div className="md:hidden sticky top-0 z-20">
                <MobileNavbar onSidebarToggle={toggleSidebar} />
            </div>

            <div className="flex flex-grow overflow-hidden">
                {/* Sidebar */}
                <div
                    className={`fixed inset-y-0 left-0 transform bg-white shadow-md transition-transform duration-300 ease-in-out z-30 md:relative md:translate-x-0 ${
                        sidebarOpen ? 'translate-x-0' : '-translate-x-full'
                    } md:flex md:flex-col md:w-64 w-64`}
                >
                    <Sidebar />
                </div>

                {/* Overlay to close sidebar on mobile */}
                {sidebarOpen && (
                    <div
                        className="fixed inset-0 bg-black opacity-50 z-10 md:hidden"
                        onClick={toggleSidebar}
                    ></div>
                )}

                {/* Main content area */}
                <div className="flex-1 p-6 overflow-y-auto ">
                    {loading ? <LoadingScreen /> : <Outlet />}
                </div>
            </div>
        </div>
    );
};

export default Layout;
