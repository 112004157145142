import React from 'react';
import { useI18n } from "../../i18n/I18nContext";

interface JobGeneratingLoadingScreenProps {
    message: string;
}

const JobGeneratingLoadingScreen: React.FC<JobGeneratingLoadingScreenProps> = ({ message }) => {
    const { translations } = useI18n();
    return (
        <div className="fixed inset-0 bg-[#0a2540] bg-opacity-75 flex flex-col items-center justify-center z-50">
            <div className="text-center text-white mx-auto ">
                <div className="relative mb-4 mx-auto w-fit">
                    <div className="w-16 h-16 border-4 border-primary border-t-transparent border-b-transparent rounded-full animate-spin"></div>
                    <div className="absolute top-0 left-0 w-16 h-16 border-4 border-primaryYellow border-l-transparent border-r-transparent rounded-full animate-spin-reverse"></div>
                </div>
                <h2 className="text-2xl font-semibold text-primaryYellow mb-4">{message}</h2>
                <p className="text-sm text-white">{translations.jobs.loadingScreenDefaultMessage}</p>
            </div>
        </div>
    );
};

export default JobGeneratingLoadingScreen;
