import React from 'react';

const HomeSkeleton: React.FC = () => {
    return (
        <div className="min-h-screen bg-gray-100 p-8">
            <div className="max-w-5xl mx-auto bg-white shadow-md rounded-lg p-8 animate-pulse">
                {/* Title */}
                <div className="h-10 bg-gray-300 rounded mb-6 mx-auto w-2/3"></div>

                {/* Dashboard */}
                <div className="mb-8">
                    <div className="h-8 bg-gray-300 rounded mb-4 w-1/4"></div>
                    <div className="grid grid-cols-1 sm:grid-cols-4 gap-4">
                        <div className="bg-gray-300 h-20 rounded-lg"></div>
                        <div className="bg-gray-300 h-20 rounded-lg"></div>
                        <div className="bg-gray-300 h-20 rounded-lg"></div>
                        <div className="bg-gray-300 h-20 rounded-lg"></div>
                    </div>
                </div>

                {/* Step 1 */}
                <div className="mb-8">
                    <div className="h-8 bg-gray-300 rounded mb-4 w-1/4"></div>
                    <div className="h-6 bg-gray-300 rounded mb-2 w-3/4"></div>
                    <div className="h-10 bg-gray-300 rounded w-1/4"></div>
                </div>

                {/* Step 2 */}
                <div className="mb-8">
                    <div className="h-8 bg-gray-300 rounded mb-4 w-1/4"></div>
                    <div className="h-6 bg-gray-300 rounded mb-2 w-3/4"></div>
                    <div className="h-10 bg-gray-300 rounded w-1/4"></div>
                </div>

                {/* Step 3 */}
                <div className="mb-8">
                    <div className="h-8 bg-gray-300 rounded mb-4 w-1/4"></div>
                    <div className="h-6 bg-gray-300 rounded mb-2 w-3/4"></div>
                    <div className="h-10 bg-gray-300 rounded w-1/4"></div>
                </div>

                {/* Support Text */}
                <div className="h-4 bg-gray-300 rounded mx-auto w-1/2"></div>
            </div>
        </div>
    );
};

export default HomeSkeleton;
