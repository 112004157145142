import React, { useState } from 'react';
import axios from 'axios';
import { useAuth } from "../auth/AuthContext";
import { useI18n } from "../i18n/I18nContext";
import LoadingScreen from "../components/LoadingScreen";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ClipboardIcon } from '@heroicons/react/16/solid';
import UserList from "../components/UserList/UserList";

const Settings: React.FC = () => {
    const { user } = useAuth();
    const { translations } = useI18n();
    const [newUserEmailInput, setNewUserEmailInput] = useState<string>('');
    const [newUserEmail, setNewUserEmail] = useState<string>('');
    const [newUserName, setNewUserName] = useState<string>('');
    const [newUserPermission, setNewUserPermission] = useState<string>('none');
    const [acceptEmail, setAcceptEmail] = useState<boolean>(false);
    const [currentPassword, setCurrentPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [generatedPassword, setGeneratedPassword] = useState<string | null>(null);
    const [passwordError, setPasswordError] = useState<string | null>(null);
    const [confirmPasswordError, setConfirmPasswordError] = useState<string | null>(null);
    const isAdmin = user!.permission === "admin";
    // Function to generate a random password
    const generateRandomPassword = (): string => {
        return Math.random().toString(36).slice(-10); // Generates a 10-character random password
    };

    const validatePassword = (password: string) => {
        const hasNumber = /\d/;
        const hasLetter = /[a-zA-Z]/;
        if (password.length < 6) {
            return translations.settings.passwordTooShort;
        }
        if (!hasNumber.test(password)) {
            return translations.settings.passwordNoNumber;
        }
        if (!hasLetter.test(password)) {
            return translations.settings.passwordNoLetter;
        }
        return null;
    };

    const validateConfirmPassword = () => {
        if (newPassword !== confirmPassword) {
            return translations.settings.passwordsDoNotMatch;
        }
        return null;
    };

    // Create new user
    const handleCreateUser = async () => {
        setLoading(true);
        const password = generateRandomPassword();

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/auth/create-user`, {
                email: newUserEmailInput,
                password,
                name: newUserName,
                accept_email: acceptEmail,
                permission: newUserPermission
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                },
            });

            if (response.status === 200) {
                setGeneratedPassword(password);
                setNewUserEmail(newUserEmailInput);
                toast.success(translations.settings.userCreatedSuccessfully);
                setNewUserEmailInput('');
                setNewUserName('');
                setNewUserPermission('none');
                setAcceptEmail(false);
            } else {
                setGeneratedPassword(null);
                toast.error(translations.settings.errorCreatingUser);
            }
        } catch (error) {
            setGeneratedPassword(null);
            toast.error(translations.settings.errorCreatingUser);
        } finally {
            setLoading(false);
        }
    };

    // Change password
    const handleChangePassword = async () => {
        const error = validatePassword(newPassword);
        const confirmPasswordError = validateConfirmPassword();

        if (error || confirmPasswordError) {
            setPasswordError(error);
            setConfirmPasswordError(confirmPasswordError);
            return;
        }

        setLoading(true);
        setPasswordError(null);
        setConfirmPasswordError(null);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/auth/change-password`, {
                email: user?.email,
                current_password: currentPassword,
                new_password: newPassword,
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                },
            });

            if (response.status === 200) {
                toast.success(translations.settings.passwordChangedSuccessfully);
                setCurrentPassword('');
                setNewPassword('');
                setConfirmPassword('');
            } else {
                toast.error(translations.settings.errorChangingPassword);
            }
        } catch (error) {
            toast.error(translations.settings.errorChangingPassword);
        } finally {
            setLoading(false);
        }
    };

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text);
        toast.info(translations.settings.copiedToClipboard);
    };

    return (
        <div className="p-6 max-w-3xl  mx-auto">
            {loading && <LoadingScreen />}
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar />

            <div className="bg-white shadow-md rounded-lg p-8">
                <h1 className="text-2xl font-bold mb-4">{translations.settings.title}</h1>

                {/* Create New User */}
                {isAdmin &&
                <div className="mb-6">
                    <h2 className="text-xl font-semibold mb-4">{translations.settings.createUser}</h2>
                    <input
                        type="text"
                        value={newUserName}
                        onChange={(e) => setNewUserName(e.target.value)}
                        placeholder={translations.settings.namePlaceholder}
                        className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-400 mb-2"
                    />
                    <input
                        type="email"
                        value={newUserEmailInput}
                        onChange={(e) => setNewUserEmailInput(e.target.value)}
                        placeholder={translations.settings.emailPlaceholder}
                        className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-400 mb-2"
                    />
                    <label className="block text-gray-700 mb-2">{translations.settings.permissionLabel}</label>
                    <select
                        value={newUserPermission}
                        onChange={(e) => setNewUserPermission(e.target.value as 'none' | 'admin')}
                        className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-400 mb-2"
                    >
                        <option value="none">{translations.settings.permissionNone}</option>
                        <option value="admin">{translations.settings.permissionAdmin}</option>
                    </select>
                    <div className="flex items-center mb-2">
                        <input
                            type="checkbox"
                            checked={acceptEmail}
                            onChange={(e) => setAcceptEmail(e.target.checked)}
                            className="mr-2"
                        />
                        <label className="text-gray-700">{translations.settings.acceptEmailLabel}</label>
                    </div>
                    <button
                        onClick={handleCreateUser}
                        className="bg-primary text-white px-4 py-2 rounded hover:bg-blue-600 transition-transform transform hover:scale-105"
                        disabled={loading}
                    >
                        {translations.settings.createUserButton}
                    </button>
                    {generatedPassword && (
                        <div className="mt-4">
                            <div className="flex items-center mb-2">
                                <input
                                    type="text"
                                    value={newUserEmail}
                                    readOnly
                                    className="w-full p-2 border rounded-l focus:outline-none bg-gray-100"
                                />
                                <button
                                    onClick={() => copyToClipboard(newUserEmail)}
                                    className="bg-primary text-white w-10 h-10 px-2 py-2 rounded-r hover:bg-blue-600"
                                >
                                    <ClipboardIcon />
                                </button>
                            </div>
                            <div className="flex items-center">
                                <input
                                    type="text"
                                    value={generatedPassword}
                                    readOnly
                                    className="w-full p-2 border rounded-l  focus:outline-none bg-gray-100"
                                />
                                <button
                                    onClick={() => copyToClipboard(generatedPassword)}
                                    className="bg-primary text-white w-10 h-10 px-2 py-2 rounded-r hover:bg-blue-600"
                                >
                                    <ClipboardIcon />
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                }
                {/* Change Password */}
                <div className="mb-6">
                    <h2 className="text-xl font-semibold mb-4">{translations.settings.changePassword}</h2>
                    <input
                        type="password"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        placeholder={translations.settings.currentPasswordPlaceholder}
                        className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-400 mb-2"
                    />
                    <input
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        placeholder={translations.settings.newPasswordPlaceholder}
                        className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-400 mb-2"
                    />
                    <input
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        placeholder={translations.settings.confirmPasswordPlaceholder}
                        className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-400 mb-2"
                    />
                    {passwordError && (
                        <p className="text-red-500 text-sm mb-2">{passwordError}</p>
                    )}
                    {confirmPasswordError && (
                        <p className="text-red-500 text-sm mb-2">{confirmPasswordError}</p>
                    )}
                    <button
                        onClick={handleChangePassword}
                        className="bg-primary text-white px-4 py-2 rounded hover:bg-blue-600 transition-transform transform hover:scale-105"
                        disabled={loading}
                    >
                        {translations.settings.changePasswordButton}
                    </button>
                </div>
            </div>
            {isAdmin && <UserList />}
        </div>
    );
};

export default Settings;
