import React, {useState, useCallback, useEffect} from 'react';
import { useDropzone } from 'react-dropzone';
import { InboxArrowDownIcon, PencilSquareIcon } from '@heroicons/react/24/solid';
import axios from 'axios';
import { useAuth } from "../auth/AuthContext";
import { useI18n } from "../i18n/I18nContext";
import LoadingScreen from "../components/LoadingScreen";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useNavigate} from "react-router-dom";
import Papa from 'papaparse';
import {CheckBadgeIcon, XCircleIcon} from "@heroicons/react/16/solid";

interface TranslationPair {
    original: string;
    translated: string;
    direction: string;
}


const ImproveTheAi: React.FC = () => {
    const { translations } = useI18n();
    const { token } = useAuth();
    const [csvFile, setCsvFile] = useState<File | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [initialLoading, setInitialLoading] = useState<boolean>(false);
    const [showImportForm, setShowImportForm] = useState<boolean>(false);
    const [translationsId, setTranslationsId] = useState('');
    const [translationsPairs, setTranslationsPairs] = useState<TranslationPair[]>([]);
    const [applyToAll, setApplyToAll] = useState<boolean>(false);
    const {user} = useAuth();
    const isAdmin = user!.permission === "admin";
    const navigate = useNavigate();
    if(!isAdmin) {
        navigate(-1)
    }
    useEffect(() => {
        setInitialLoading(true)
        handleFetchTranslations()
        setInitialLoading(false)
    }, []);
    // Handle CSV Import
    const handleImportCsv = async () => {
        if (!csvFile) return;
        setTranslationsPairs([])
        setLoading(true);

        const formData = new FormData();
        formData.append("file", csvFile);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/index_from_csv/`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 200) {
                toast.success(translations.improveTheAi.importSuccess);
                setCsvFile(null);
            } else {
                toast.error(translations.improveTheAi.importError);
            }
        } catch (error) {
            toast.error(translations.improveTheAi.importError);
        } finally {
            setLoading(false);
        }
    };

    // Handle Fetch Translations
    const handleFetchTranslations = async () => {
        setShowImportForm(false)
        if (translationsPairs.length !== 0) return;
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/feedback/get-translations-to-index`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status === 200) {
                setTranslationsId(response.data.translations.id);
                setTranslationsPairs(response.data.translations.translation_pairs.map((pair: TranslationPair) => ({
                    ...pair,
                    direction: 'c-e',
                })));
            } else {
                toast.error(translations.improveTheAi.fetchTranslationsError);
            }
        } catch (error) {
            toast.error(translations.improveTheAi.fetchTranslationsError);
        } finally {
            setLoading(false);
        }
    };

    const onDrop = useCallback((acceptedFiles: File[]) => {
        const file = acceptedFiles[0];

        Papa.parse(file, {
            complete: (result: { data: string[][]; }) => {
                const rows = result.data as string[][];
                if (rows.length > 1000) {
                    toast.error(translations.improveTheAi.csvTooLarge); // Add translation for this
                    return;
                }
                setCsvFile(file);
            },
            error: (error: any) => {
                console.log(error)
                toast.error(translations.improveTheAi.csvParseError); // Add translation for this
            }
        });
    }, [translations.improveTheAi.csvTooLarge, translations.improveTheAi.csvParseError]);
    const handleRemoveTranslation = (index: number) => {
        const updatedPairs = translationsPairs.filter((_, i) => i !== index);
        setTranslationsPairs(updatedPairs);
    };
    // Handle input changes in the translation pairs
    const handleInputChange = (index: number, field: 'original' | 'translated' | 'direction', value: string) => {
        const updatedPairs = [...translationsPairs];
        updatedPairs[index][field] = value;
        setTranslationsPairs(updatedPairs);
    };

    // Submit the edited translation pairs to the API
    const handleSubmitTranslations = async () => {
        const userId = user?.id;
        if (!userId) throw new Error('User id not found');
        const indexFeedbackData = {
            id: translationsId,
            user_id: userId,
            pairs: translationsPairs.map((pair) => ({
                original: pair.original,
                translated: pair.translated,
                direction: pair.direction
            })),
        };

        setLoading(true);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/feedback/index`, indexFeedbackData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                toast.success(translations.improveTheAi.submitSuccess);
                setTranslationsPairs([]);
                setTranslationsId('')
            } else {
                toast.error(translations.improveTheAi.submitError);
            }
        } catch (error) {
            toast.error(translations.improveTheAi.submitError);
        } finally {
            setLoading(false);
        }
    };
    const handleBulkDirectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newDirection = event.target.checked ? 'e-c' : 'c-e';
        const updatedPairs = translationsPairs.map(pair => ({
            ...pair,
            direction: newDirection,
        }));
        setTranslationsPairs(updatedPairs);
        setApplyToAll(event.target.checked);
    };
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: { 'text/csv': ['.csv'] },
        multiple: false,
    });
    if(initialLoading){
        return <LoadingScreen />
    }
    // Render translation pairs for auditing
    const renderTranslationPairs = () => {
        return translationsPairs.map((pair, pairIndex) => (
                <tr key={`${pairIndex}`}>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm">{pair.original}</td>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm">
                        <input
                            type="text"
                            value={pair.translated}
                            onChange={(e) => handleInputChange(pairIndex, 'translated', e.target.value)}
                            className="w-full p-2 border rounded focus:outline-none"
                        />
                    </td>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm">
                        <select
                            value={pair.direction}
                            onChange={(e) => handleInputChange(pairIndex, 'direction', e.target.value)}
                            className="w-full p-2 border rounded focus:outline-none"
                        >
                            <option value="c-e">{translations.improveTheAi.chineseToEnglish}</option>
                            <option value="e-c">{translations.improveTheAi.englishToChinese}</option>
                        </select>
                    </td>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm text-center col-span-1">
                        <button onClick={() => handleRemoveTranslation(pairIndex)} className="text-red-500 hover:text-red-700">
                            <XCircleIcon className="h-5 w-5 inline" />
                        </button>
                    </td>
                </tr>
            ));
    };

    return (
        <div className="p-6 max-w-4xl mx-auto">
            {loading && <LoadingScreen />}
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar />

            <div className="bg-white shadow-md rounded-lg p-8">
                <h1 className="text-2xl font-bold mb-6">{translations.improveTheAi.title}</h1>

                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                    <button
                        onClick={() => setShowImportForm(!showImportForm)}
                        className="bg-primary text-white px-4 py-2 rounded hover:bg-blue-600 flex items-center justify-center gap-2 transition-transform transform hover:scale-105"
                    >
                        <InboxArrowDownIcon className="h-6 w-6" />
                        {translations.improveTheAi.importFromCsv}
                    </button>
                    <button
                        onClick={handleFetchTranslations}
                        className="bg-primary text-white px-4 py-2 rounded hover:bg-blue-600 flex items-center justify-center gap-2 transition-transform transform hover:scale-105"
                    >
                        <PencilSquareIcon className="h-6 w-6" />
                        {translations.improveTheAi.auditStaffTranslations}
                    </button>
                </div>

                {showImportForm && (
                    <div className="mt-6">
                        <div
                            {...getRootProps()}
                            className="border-2 border-dashed border-gray-400 p-6 text-center rounded-lg cursor-pointer focus:outline-none"
                        >
                            <input {...getInputProps()} />
                            {isDragActive ? (
                                <p>{translations.improveTheAi.dragActiveText}</p>
                            ) : (
                                <p>{translations.improveTheAi.dragDropText}</p>
                            )}
                        </div>
                        {csvFile && (
                            <div className="mt-4 text-center">
                                <p>{csvFile.name}</p>
                                <button
                                    onClick={handleImportCsv}
                                    className="mt-4 bg-primary text-white px-4 py-2 rounded hover:bg-blue-600 transition-transform transform hover:scale-105"
                                    disabled={!csvFile || loading}
                                >
                                    {translations.improveTheAi.importButton}
                                </button>
                            </div>
                        )}
                    </div>
                )}

                {!showImportForm && translationsPairs.length > 0 ? (
                    <div className="mt-8 mb-8 overflow-x-auto ">
                        <table className=" bg-white shadow-md rounded-lg">
                            <thead>
                            <tr>
                                <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-xs leading-4 text-gray-600 uppercase tracking-wider">
                                    {translations.improveTheAi.originalText}
                                </th>
                                <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-xs leading-4 text-gray-600 uppercase tracking-wider">
                                    {translations.improveTheAi.translatedText}
                                </th>
                                <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-xs leading-4 text-gray-600 uppercase tracking-wider">
                                    {translations.improveTheAi.translationDirection}
                                    <input
                                        type="checkbox"
                                        className="ml-2"
                                        checked={applyToAll}
                                        onChange={handleBulkDirectionChange}
                                    />
                                </th>
                                <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-xs leading-4 text-gray-600 uppercase tracking-wider">

                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {renderTranslationPairs()}
                            </tbody>
                        </table>
                        <div className="fixed shadow-md w-full bottom-0 left-0 p-2 bg-white">
                        <button
                            onClick={handleSubmitTranslations}
                            className="mx-auto block w-fit bg-primary text-white px-4 py-2 rounded hover:bg-blue-600 transition-transform transform hover:scale-105"
                        >
                            {translations.improveTheAi.submitButton}
                        </button>
                        </div>
                    </div>
                ): (
                    <div className="mt-8 mx-auto w-fit text-center block">
                        <CheckBadgeIcon className=" text-center w-24 h-24 text-gray-500 mx-auto"/>
                        <h2 className="font-semibold text-gray-500 text-lg">Great work, you've translated everything!</h2>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ImproveTheAi;
