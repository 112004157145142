import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../auth/AuthContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import JobGeneratingLoadingScreen from "./JobGeneratingLoadingScreen";
import { useI18n } from '../../i18n/I18nContext';
import {XCircleIcon} from "@heroicons/react/16/solid";

interface TranslationPair {
    original: string;
    translated: string;
}

const JobAuditTranslations: React.FC = () => {
    const { jobId } = useParams<{ jobId: string }>();
    const { user } = useAuth();
    const navigate = useNavigate();
    const { translations } = useI18n();

    const [translationPairs, setTranslationPairs] = useState<TranslationPair[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [loadingMessage, setLoadingMessage] = useState<string>(''); // Custom loading message

    useEffect(() => {
        const fetchTranslationPairs = async () => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/feedback/init`, {
                    user_id: user?.id,
                    job_id: jobId,
                }, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    },
                });

                if (response.status === 200) {
                    setTranslationPairs(response.data.translation_pairs);
                } else {
                    toast.error(translations.jobAuditTranslations.loadingTranslationPairsMessage);
                }
            } catch (error) {
                toast.error(translations.jobAuditTranslations.loadingTranslationPairsMessage);
            } finally {
                setLoading(false);
            }
        };

        fetchTranslationPairs();
    }, [jobId, user, translations]);

    const handleInputChange = (index: number, newValue: string) => {
        const updatedPairs = [...translationPairs];
        updatedPairs[index].translated = newValue;
        setTranslationPairs(updatedPairs);
    };
    const handleRemoveTranslation = (index: number) => {
        const updatedPairs = translationPairs.filter((_, i) => i !== index);
        setTranslationPairs(updatedPairs);
    };

    const handleSubmit = async () => {
        setSubmitting(true);
        setLoadingMessage(translations.jobAuditTranslations.submittingTranslationsMessage);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/feedback/receive`, {
                user_id: user?.id,
                job_id: jobId,
                translation_pairs: translationPairs,
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                },
            });

            if (response.status === 200) {
                setLoadingMessage(translations.jobAuditTranslations.generatingPdfMessage);
                // Navigate back to /jobs with success toast
                navigate('/jobs', { state: { success: translations.jobAuditTranslations.generatingPdfMessage } });
            } else {
                toast.error(translations.jobAuditTranslations.submittingTranslationsMessage);
            }
        } catch (error) {
            toast.error(translations.jobAuditTranslations.submittingTranslationsMessage);
        } finally {
            setSubmitting(false);
            setLoadingMessage(''); // Clear the loading message
        }
    };

    if (loading) {
        return <JobGeneratingLoadingScreen message={translations.jobAuditTranslations.loadingTranslationPairsMessage} />;
    }

    return (
        <div className="p-6 max-w-4xl mx-auto pb-20"> {/* Added pb-20 for padding at the bottom */}
            {submitting && <JobGeneratingLoadingScreen message={loadingMessage} />} {/* Show loading screen during submission */}
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar />
            <button
                onClick={() => navigate('/jobs')}
                className="text-primary flex items-center mb-4"
            >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
                {translations.jobAuditTranslations.backToJobs}
            </button>
            <h1 className="text-2xl font-bold mb-4 text-center">{translations.jobAuditTranslations.title}</h1>

            <div className="overflow-x-auto">
                <table className="min-w-full bg-white shadow-md rounded-lg grid w-full">
                    <thead>
                    <tr className="grid grid-cols-5">
                        <th className="col-span-2 px-6 py-3 border-b-2 border-gray-300 text-left text-xs leading-4 text-gray-600 uppercase tracking-wider">{translations.jobAuditTranslations.originalText}</th>
                        <th className="col-span-3 px-6 py-3 border-b-2 border-gray-300 text-left text-xs leading-4 text-gray-600 uppercase tracking-wider">{translations.jobAuditTranslations.translatedText}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {translationPairs.map((pair, index) => (
                        <tr className="grid grid-cols-5" key={index}>
                            <td className="px-6 py-4 border-b border-gray-300 text-sm col-span-2">{pair.original}</td>
                            <td className="px-6 py-4 border-b border-gray-300 text-sm col-span-2">
                                <input
                                    type="text"
                                    value={pair.translated}
                                    onChange={(e) => handleInputChange(index, e.target.value)}
                                    className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
                                />
                            </td>
                            <td className="px-6 py-4 border-b border-gray-300 text-sm text-center col-span-1">
                                <button onClick={() => handleRemoveTranslation(index)} className="text-red-500 hover:text-red-700">
                                    <XCircleIcon className="h-5 w-5 inline" />
                                </button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>

            <div className="fixed bottom-0 left-0 z-40 right-0 bg-white border-t border-gray-300 py-4 flex justify-between px-6">
                <button
                    onClick={() => navigate('/jobs')}
                    className="text-primary flex items-center"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                    </svg>
                    {translations.jobAuditTranslations.backToJobs}
                </button>
                <button
                    onClick={handleSubmit}
                    className="bg-primary text-white px-4 py-2 rounded hover:bg-blue-600 transition-transform transform hover:scale-105"
                    disabled={submitting}
                >
                    {submitting ? translations.jobAuditTranslations.submitting : translations.jobAuditTranslations.submitTranslations}
                </button>
            </div>
        </div>
    );
};

export default JobAuditTranslations;
