import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import LoadingScreen from '../components/LoadingScreen';

const PrivateRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { token, loading } = useAuth();

    if (loading) {
        // Show loading screen while authentication status is being determined
        return <LoadingScreen />;
    }

    if (token) {
        // If the user is authenticated, render the protected component
        return <>{children}</>;
    }

    // If the user is not authenticated, redirect to the sign-in page
    return <Navigate to="/sign-in" replace />;
};

export default PrivateRoute;
