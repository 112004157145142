import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import UserListSkeletonCard from "./UserListSkeleton";
import {useI18n} from "../../i18n/I18nContext";
import LoadingScreen from "../LoadingScreen";

const UserList: React.FC = () => {
    const [users, setUsers] = useState<Array<{ id: string, email: string, name: string, permission: string }>>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
    const { translations } = useI18n();

    // Fetch users from the API
    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/auth/get-all-users`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    },
                });

                if (response.status === 200) {
                    setUsers(response.data.users);
                } else {
                    toast.error('Error fetching users');
                }
            } catch (error) {
                toast.error('Error fetching users');
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    }, []);

    // Handle delete user
    const handleDeleteUser = async (userId: string) => {
        const confirmDelete = window.confirm(translations.settings.usersList.deleteConfirmation);
        if (!confirmDelete) return;
        setDeleteLoading(true)
        try {
            const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/auth/delete-user/${userId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                },
            });

            if (response.status === 200) {
                toast.success(translations.success);
                setUsers(users.filter(user => user.id !== userId)); // Remove user from local state
                setDeleteLoading(false)
            } else {
                toast.error(translations.errors.genericErrorMessage);
                setDeleteLoading(false)
            }
        } catch (error) {
            toast.error(translations.errors.genericErrorMessage);
            setDeleteLoading(false)
        }
    };

    if (loading) {
        return <UserListSkeletonCard />;
    }

    return (
        <div className="bg-white shadow-md rounded-lg p-8 mt-6">
            {deleteLoading && <LoadingScreen />}
            <h2 className="text-xl font-semibold mb-4">{translations.settings.usersList.title}</h2>
            <table className="min-w-full table-auto ">
                <thead>
                <tr className="bg-gray-100 rounded-lg">
                    <th className="px-4 text-center py-2">{translations.settings.usersList.email}</th>
                    <th className="px-4 text-center py-2">{translations.settings.usersList.name}</th>
                    <th className="px-4 text-center py-2">{translations.settings.usersList.permission}</th>
                    <th className="px-4 text-center py-2">{translations.settings.usersList.actions}</th>
                </tr>
                </thead>
                <tbody>
                {users.length === 0 ? (
                    <tr>
                        <td colSpan={4} className="text-center py-4">{translations.settings.usersList.noUsersFound}</td>
                    </tr>
                ) : (
                    users.map((user, index) => (
                        <tr key={index} className="border-t">
                            <td className="px-4 py-2">{user.email}</td>
                            <td className="px-4 text-center py-2">{user.name}</td>
                            <td className="px-4 text-center py-2 capitalize">{user.permission}</td>
                            <td className="px-4 text-center py-2">
                                <button
                                    className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-700 transition-transform transform hover:scale-105"
                                    onClick={() => handleDeleteUser(user.id)}
                                >
                                    {translations.settings.usersList.deleteButton}
                                </button>
                            </td>
                        </tr>
                    ))
                )}
                </tbody>
            </table>
        </div>
    );
};

export default UserList;
