import React from 'react';
import {useI18n} from "../i18n/I18nContext";
import {useAuth} from "../auth/AuthContext";
import {useNavigate} from "react-router-dom";

const Analytics: React.FC = () => {
    const { translations } = useI18n();
    const {user} = useAuth();
    const isAdmin = user!.permission === "admin";
    const navigate = useNavigate();
    if(!isAdmin) {
        navigate(-1)
    }
    return (
        <div className="bg-white shadow-md rounded-lg p-6">
            <h2 className="text-xl font-semibold text-gray-800">{translations.analytics.title}</h2>
            <p className="mt-4 text-gray-600">{translations.analytics.message}</p>
        </div>
    );
};

export default Analytics;
