import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useI18n } from "../i18n/I18nContext";
import { useAuth } from "../auth/AuthContext";
import { config } from "../config";
import HomeSkeleton from "../components/Skeletons/HomeSkeleton";

const Home: React.FC = () => {
    const { translations } = useI18n();
    const navigate = useNavigate();
    const { user } = useAuth();
    const [jobCounts, setJobCounts] = useState({
        initiated: 0,
        checkpointOne: 0,
        checkpointTwo: 0,
        complete: 0,
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchJobCounts = async () => {
            try {
                const response = await axios.get(`${config.API_BASE_URL}/api/job/get-all-jobs/${user?.id}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    },
                });

                if (response.status === 200) {
                    const jobs = response.data.jobs;
                    const counts = { initiated: 0, checkpointOne: 0, checkpointTwo: 0, complete: 0 };

                    jobs.forEach((job: any) => {
                        switch (job.status) {
                            case 'initiated':
                                counts.initiated++;
                                break;
                            case 'checkpoint_one':
                                counts.checkpointOne++;
                                break;
                            case 'checkpoint_two':
                                counts.checkpointTwo++;
                                break;
                            case 'complete':
                                counts.complete++;
                                break;
                            default:
                                break;
                        }
                    });

                    setJobCounts(counts);
                }
            } catch (error) {
                console.error('Error fetching job counts:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchJobCounts();
    }, [user?.id]);

    const handleNavigation = (path: string) => {
        navigate(path);
    };
    if (loading) {
        return <HomeSkeleton />; // Render the skeleton screen while loading
    }

    return (
        <div className="min-h-screen bg-gray-100 p-8">
            <div className="max-w-5xl mx-auto bg-white shadow-md rounded-lg p-8">
                <h1 className="text-3xl font-bold text-1d2975 mb-6 text-center">{translations.homePage.welcomeTitle}</h1>

                <div className="mb-8">
                    <h2 className="text-2xl font-semibold text-gray-800 mb-4">{translations.homePage.dashboardTitle}</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-4 gap-4">
                        <div className="bg-gray-100 p-4 rounded-lg shadow-md text-center">
                            <h3 className="text-xl font-semibold text-1d2975">{translations.homePage.initiated}</h3>
                            <p className="text-2xl font-bold text-gray-800">{jobCounts.initiated}</p>
                        </div>
                        <div className="bg-gray-100 p-4 rounded-lg shadow-md text-center">
                            <h3 className="text-xl font-semibold text-1d2975">{translations.homePage.checkpointOne}</h3>
                            <p className="text-2xl font-bold text-gray-800">{jobCounts.checkpointOne}</p>
                        </div>
                        <div className="bg-gray-100 p-4 rounded-lg shadow-md text-center">
                            <h3 className="text-xl font-semibold text-1d2975">{translations.homePage.checkpointTwo}</h3>
                            <p className="text-2xl font-bold text-gray-800">{jobCounts.checkpointTwo}</p>
                        </div>
                    </div>
                </div>

                <div className="mb-8">
                    <h2 className="text-2xl font-semibold text-gray-800 mb-4">{translations.homePage.step1Title}</h2>
                    <p className="text-gray-600 mb-2">{translations.homePage.step1Description}</p>
                    <button
                        onClick={() => handleNavigation('/translation-assistant')}
                        className="bg-primary text-white px-4 py-2 rounded hover:bg-indigo-800 transition-transform transform hover:scale-105"
                    >
                        {translations.homePage.aiTranslationAssistantButton}
                    </button>
                </div>

                <div className="mb-8">
                    <h2 className="text-2xl font-semibold text-gray-800 mb-4">{translations.homePage.step2Title}</h2>
                    <p className="text-gray-600 mb-2">{translations.homePage.step2Description}</p>
                    <button
                        onClick={() => handleNavigation('/jobs/init')}
                        className="bg-primary text-white px-4 py-2 rounded hover:bg-indigo-800 transition-transform transform hover:scale-105"
                    >
                        {translations.homePage.createJobButton}
                    </button>
                </div>

                <div className="mb-8">
                    <h2 className="text-2xl font-semibold text-gray-800 mb-4">{translations.homePage.step3Title}</h2>
                    <p className="text-gray-600 mb-2">{translations.homePage.step3Description}</p>
                    <button
                        onClick={() => handleNavigation('/jobs')}
                        className="bg-primary text-white px-4 py-2 rounded hover:bg-indigo-800 transition-transform transform hover:scale-105"
                    >
                        {translations.homePage.viewJobsButton}
                    </button>
                </div>

                <div className="text-center">
                    <p className="text-gray-500">{translations.homePage.supportText}</p>
                </div>
            </div>
        </div>
    );
};

export default Home;
