import React from 'react';

const LoadingScreen: React.FC = () => {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-primaryDeepBlue bg-opacity-95 z-50">
            <div className="flex flex-col items-center">
                <div className="relative">
                    <div className="w-16 h-16 border-4 border-primary border-t-transparent border-b-transparent rounded-full animate-spin"></div>
                    <div className="absolute top-0 left-0 w-16 h-16 border-4 border-primaryYellow border-l-transparent border-r-transparent rounded-full animate-spin-reverse"></div>
                </div>
                <p className="text-primaryYellow text-lg mt-4">Loading...</p>
            </div>
        </div>
    );
};

export default LoadingScreen;
