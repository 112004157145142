import React, { useState } from 'react';
import { useAuth } from '../auth/AuthContext';
import {useI18n} from "../i18n/I18nContext";
import LanguageToggle from "../components/Buttons/LanguageToggle";
import {useNavigate} from "react-router-dom";

const SignIn: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState<string | null>(null);
    const [signInLoading, setSignInLoading] = useState(false);
    const { signIn } = useAuth();
    const { translations } = useI18n();

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setSignInLoading(true)
        setError(null);
        try {
            await signIn(email, password);
            // If successful, the user will be redirected by the AuthContext
        } catch (e) {
            setError('Failed to sign in. Please check your credentials and try again.');
            setSignInLoading(false)
        }
    };

    return (
        <div className="relative flex justify-center items-center h-screen bg-gradient-to-br from-purple-500 via-blue-600 to-indigo-700 overflow-hidden">
            {/* Animated Background */}
            <div className="absolute inset-0 overflow-hidden">
                {/* Background Circles */}
                <div className="absolute top-1/4 left-1/4 w-72 h-72 bg-pink-600 opacity-40 rounded-full filter blur-xl animate-pulse"></div>
                <div className="absolute top-3/4 left-1/4 w-96 h-96 bg-purple-700 opacity-30 rounded-full filter blur-2xl animate-pulse"></div>
                <div className="absolute top-0 right-1/3 w-80 h-80 bg-blue-700 opacity-30 rounded-full filter blur-lg animate-pulse"></div>
                <div className="absolute bottom-1/4 right-1/4 w-80 h-80 bg-yellow-600 opacity-30 rounded-full filter blur-2xl animate-pulse"></div>
                <div className="absolute bottom-1/4 left-1/3 w-96 h-96 bg-green-600 opacity-20 rounded-full filter blur-2xl animate-pulse"></div>
            </div>

            {/* Login Form */}
            <form onSubmit={handleSubmit} className="relative bg-white p-8 rounded-lg shadow-lg w-full max-w-sm z-10">
                <div className="flex justify-center mb-6">
                    <img src='/assets/images/arcular_logo.png' alt="Arcular Logo" className="h-20 w-20" />
                    <img src='/assets/images/superu_logo.png' alt="SuperU Logo" className="h-20 w-20" />
                </div>
                <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">{translations.signIn.title}</h2>
                {error && <p className="text-red-500 mb-4 text-center">{error}</p>}
                <div className="mb-4">
                    <label className="block text-gray-700">{translations.signIn.email}</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder={translations.signIn.email}
                        className="w-full p-3 border rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
                        required
                    />
                </div>
                <div className="mb-6">
                    <label className="block text-gray-700">{translations.signIn.password}</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder={translations.signIn.password}
                        className="w-full p-3 border rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
                        required
                    />
                </div>
                <button disabled={signInLoading} type="submit" className="bg-blue-500 text-white px-4 py-2 rounded w-full hover:bg-blue-600 transition-transform transform hover:scale-105">
                    {translations.signIn.button}
                </button>
                <LanguageToggle />
            </form>
        </div>
    );
};

export default SignIn;
