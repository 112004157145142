import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import JobCard from "../../components/JobCard";  // Assuming the same JobCard component can be used
import JobsSkeletonCard from "../../components/Skeletons/JobsSkeletonCard";
import { ArrowLeftIcon, UserIcon } from '@heroicons/react/24/solid';
import {useI18n} from "../../i18n/I18nContext";
import {useAuth} from "../../auth/AuthContext";
import {User} from "../../types/User";
import {Job} from "../../types/Job";
import {useNavigate} from "react-router-dom";


const ViewAllUserJobs: React.FC = () => {
    const { translations } = useI18n();
    const { token } = useAuth();
    const [users, setUsers] = useState<User[]>([]);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
    const [jobs, setJobs] = useState<Job[]>([]);
    const [filteredJobs, setFilteredJobs] = useState<Job[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingUsers, setLoadingUsers] = useState<boolean>(true);
    const [totalJobs, setTotalJobs] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [jobSearchTerm, setJobSearchTerm] = useState<string>('');
    const jobsPerPage = 10;
    const [transition, setTransition] = useState<'left' | 'right' | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetchAllUsers();
    }, []);

    const fetchAllUsers = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/auth/get-all-users`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status === 200) {
                setUsers(response.data.users);
                setFilteredUsers(response.data.users);
            } else {
                toast.error(translations.viewAllUserJobs.errorFetchingUsers);
            }
        } catch (error) {
            toast.error(translations.viewAllUserJobs.errorFetchingUsers);
        } finally {
            setLoadingUsers(false);
        }
    };

    const fetchUserJobs = async (userId: string, page: number) => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/job/get-all-jobs/${userId}?page=${page}&page_size=${jobsPerPage}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status === 200) {
                setJobs(sortJobs(response.data.jobs));
                setFilteredJobs(sortJobs(response.data.jobs));
                setTotalJobs(response.data.total_jobs);
                setTotalPages(response.data.total_pages);
            } else {
                toast.error(translations.viewAllUserJobs.errorFetchingJobs);
            }
        } catch (error) {
            toast.error(translations.viewAllUserJobs.errorFetchingJobs);
        } finally {
            setLoading(false);
        }
    };
    const sortJobs = (jobs: Job[]) => {
        const statusPriority = ['initiated', 'checkpoint_one', 'checkpoint_two', 'complete'];

        return jobs.sort((a, b) => {
            const statusDiff = statusPriority.indexOf(a.status) - statusPriority.indexOf(b.status);
            if (statusDiff !== 0) {
                return statusDiff;
            } else {
                return new Date(b.created_at).getTime() - new Date(a.created_at).getTime(); // Sort by creation time (newest first)
            }
        });
    };
    const handleUserClick = (user: User) => {
        setTransition('left');
        setTimeout(() => {
            setSelectedUser(user);
            setTransition(null);
            fetchUserJobs(user.id, 1);
        }, 500);
    };

    const handleGoBack = () => {
        setTransition('right');
        setTimeout(() => {
            setSelectedUser(null);
            setTransition(null);
        }, 500);
    };
    const handleSearchUsers = (event: React.ChangeEvent<HTMLInputElement>) => {
        const term = event.target.value.toLowerCase();
        setSearchTerm(term);
        setFilteredUsers(users.filter(user => user.name.toLowerCase().includes(term) || user.email.toLowerCase().includes(term)));
    };

    const handleSearchJobs = (event: React.ChangeEvent<HTMLInputElement>) => {
        const term = event.target.value.toLowerCase();
        setJobSearchTerm(term);
        setFilteredJobs(jobs.filter(job => job.job_name.toLowerCase().includes(term) || job.pdf_name.toLowerCase().includes(term)));
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prevPage => prevPage + 1);
            if (selectedUser) fetchUserJobs(selectedUser.id, currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
            if (selectedUser) fetchUserJobs(selectedUser.id, currentPage - 1);
        }
    };

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
        if (selectedUser) fetchUserJobs(selectedUser.id, pageNumber);
    };
    const handleDeleteJob = (jobId: string) => {
        setFilteredJobs(filteredJobs.filter(job => job.id !== jobId));
        setJobs(jobs.filter(job => job.id !== jobId));
    };

    return (
        <div className="p-6 max-w-4xl mx-auto">
            {loadingUsers && (
                <div>
                    {Array.from({ length: 5 }).map((_, index) => (
                        <JobsSkeletonCard key={index} />
                    ))}
                </div>
            )}

            {(!selectedUser) ? (
                <>
                {(users.length > 0 )&& (
                <div
                    className={`transition-all duration-500 ${transition === 'left' ? 'opacity-0 translate-x-full' : ''}`}>
                    <button
                        onClick={() => {
                            navigate('/jobs')
                        }}
                        className="flex items-center text-primary mb-4"
                    >
                        <ArrowLeftIcon className="h-6 w-6 mr-2"/>
                        {translations.buttons.backToJobsButton}
                    </button>
                    <h1 className="text-2xl font-bold mb-4">{translations.viewAllUserJobs.title}</h1>
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={handleSearchUsers}
                        placeholder={translations.viewAllUserJobs.searchUsersPlaceholder}
                        className="mb-4 p-2 w-full border rounded"
                    />
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                        {filteredUsers.map(user => (
                            <div
                                key={user.id}
                                className="bg-white p-4 shadow-md rounded-lg cursor-pointer hover:shadow-lg transition-shadow"
                                onClick={() => handleUserClick(user)}
                            >
                                <UserIcon className="h-8 w-8 text-gray-500 mb-2"/>
                                <h2 className="text-lg font-semibold">{user.name}</h2>
                                <p className="text-gray-500">{user.email}</p>
                            </div>
                        ))}
                    </div>
                </div>
                )}
                </>
            ) : (
                <div className={`transition-all duration-500 ${transition === 'right' ? 'opacity-0 -translate-x-full' : ''}`}>
                    <button
                        onClick={handleGoBack}
                        className="flex items-center text-primary mb-4"
                    >
                        <ArrowLeftIcon className="h-6 w-6 mr-2" />
                        {translations.viewAllUserJobs.goBackButton}
                    </button>
                    <h1 className="text-2xl font-bold mb-4">{translations.viewAllUserJobs.jobsFor} {selectedUser.name}</h1>
                    {loading ? (
                        <div>
                            {Array.from({ length: 5 }).map((_, index) => (
                                <JobsSkeletonCard key={index} />
                            ))}
                        </div>
                    ) : totalJobs === 0 ? (
                        <div className="text-center mt-8 text-gray-600">
                            <p>{translations.viewAllUserJobs.noJobs}</p>
                        </div>
                    ) : (
                        <div>
                            <h4 className="text-lg font-semibold my-2">{translations.viewAllUserJobs.totalJobs}{jobs.length}</h4>
                            <input
                                type="text"
                                value={jobSearchTerm}
                                onChange={handleSearchJobs}
                                placeholder={translations.viewAllUserJobs.searchJobsPlaceholder}
                                className="mb-4 p-2 w-full border rounded"
                            />
                            <ul>
                                {filteredJobs.map(job => (
                                    <JobCard key={job.id} job={job} onDelete={handleDeleteJob}  />
                                ))}
                            </ul>
                            <div className="flex justify-center mt-4">
                                {currentPage > 1 && (
                                    <button
                                        onClick={handlePreviousPage}
                                        className="mx-1 px-3 py-1 rounded bg-gray-200 hover:bg-gray-300"
                                    >
                                        {translations.viewAllUserJobs.previous}
                                    </button>
                                )}
                                {Array.from({ length: totalPages }).map((_, index) => (
                                    <button
                                        key={index}
                                        onClick={() => handlePageChange(index + 1)}
                                        className={`mx-1 px-3 py-1 rounded ${index + 1 === currentPage ? 'bg-primary text-white' : 'bg-gray-200'}`}
                                    >
                                        {index + 1}
                                    </button>
                                ))}
                                {currentPage < totalPages && (
                                    <button
                                        onClick={handleNextPage}
                                        className="mx-1 px-3 py-1 rounded bg-gray-200 hover:bg-gray-300"
                                    >
                                        {translations.viewAllUserJobs.next}
                                    </button>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            )}

            <ToastContainer position="top-right" autoClose={5000} hideProgressBar />
        </div>
    );
};

export default ViewAllUserJobs;
