import React from 'react';

const JobsSkeletonCard: React.FC = () => {
    return (
        <div className="bg-white shadow-md rounded-lg p-4 mb-4 w-full mx-auto animate-pulse">
            <div className="h-6 bg-gray-300 rounded w-3/4 mb-4"></div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div className="h-4 bg-gray-300 rounded"></div>
                <div className="h-4 bg-gray-300 rounded"></div>
            </div>
            <div className="mt-4 h-3 bg-gray-300 rounded w-1/2"></div>
            <div className="mt-4 bg-gray-300 h-10 rounded-lg"></div>
        </div>
    );
};

export default JobsSkeletonCard;
