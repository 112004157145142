import React from 'react';

interface MobileNavbarProps {
    onSidebarToggle: () => void;
}

const MobileNavbar: React.FC<MobileNavbarProps> = ({ onSidebarToggle }) => {
    return (
        <div className="md:hidden bg-white shadow-md p-4 flex justify-between items-center">
            <button onClick={onSidebarToggle} className="text-gray-600 focus:outline-none">
                <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
                </svg>
            </button>
            <div className="text-lg font-semibold">AI Translator</div>
        </div>
    );
};

export default MobileNavbar;
