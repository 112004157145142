import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { User } from "../types/User";
import { config } from "../config";
import { useI18n } from "../i18n/I18nContext";

const API_BASE_URL = config.API_BASE_URL;

interface AuthContextProps {
    token: string | null;
    loading: boolean;
    user: User | null;
    signIn: (email: string, password: string) => Promise<void>;
    signOut: () => Promise<void>;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { translations } = useI18n();
    const [token, setToken] = useState<string | null>(localStorage.getItem('authToken'));
    const [user, setUser] = useState<User | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [showPopup, setShowPopup] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const initAuth = async () => {
            const storedUserId = localStorage.getItem('userId');
            if (token && storedUserId) {
                setLoading(true);
                try {
                    const fetchedUser = await fetchUser(token);
                    if (fetchedUser) {
                        setUser(fetchedUser);
                        if (location.pathname === '/sign-in') {
                            navigate('/');
                        }
                    } else {
                        console.error('User was not found. User value is: ' + fetchedUser)
                    }
                } catch (error) {
                    console.error('Failed to fetch user:', error);
                    handleAuthError();
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        };

        if (location.pathname !== '/sign-in') {
            initAuth();

            // Set interval to refresh user data every 10 minutes
            const intervalId = setInterval(() => {
                if (token) {
                    fetchUser(token);
                }
            }, 10 * 60 * 1000); // 10 minutes

            return () => clearInterval(intervalId); // Clear interval on component unmount
        }
    }, [location.pathname]); // Only run when the location changes

    const fetchUser = async (token: string): Promise<User | null> => {
        try {
            const response = await fetch(`${API_BASE_URL}/api/auth/current-user`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (!response.ok) {
                if (response.status === 401 || response.status === 404) {
                    throw new Error('Unauthorized or Not Found');
                }
                if (response.status === 500) {
                    console.error('User is not logged in');
                    localStorage.removeItem('authToken');
                    navigate('/sign-in');
                    return null;
                }
                throw new Error('Failed to fetch user');
            }
            const userData = await response.json();
            setUser(userData.user);
            localStorage.setItem('userId', userData.user.id); // Store only the user ID
            return userData.user;
        } catch (error) {
            console.error('Error fetching user:', error);
            handleAuthError();
            return null;
        }
    };

    const handleAuthError = () => {
        setError(translations.errors.accessTokenExpiryMsg);
        setShowPopup(true);
    };

    const signIn = async (email: string, password: string) => {
        setLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}/api/auth/sign-in`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });

            if (!response.ok) {
                throw new Error('Failed to sign in');
            }

            const data = await response.json();
            const token = data.message.session.access_token;
            setToken(token);
            localStorage.setItem('authToken', token);
            const fetchedUser = await fetchUser(token);
            if (fetchedUser) {
                setUser(fetchedUser);
                localStorage.setItem('userId', fetchedUser.id); // Store only the user ID
                navigate('/');
            } else {
                throw new Error('Failed to fetch user after sign in');
            }
        } catch (error) {
            console.error('Error signing in:', error);
            throw error;
        } finally {
            setLoading(false);
        }
    };

    const signOut = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}/api/auth/sign-out`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to sign out');
            }

            setToken(null);
            setUser(null);
            localStorage.removeItem('authToken');
            localStorage.removeItem('userId'); // Remove user ID on sign out
            navigate('/sign-in');
        } catch (error) {
            console.error('Error signing out:', error);
            navigate('/sign-in');
        } finally {
            setLoading(false);
        }
    };

    const handleSignOut = () => {
        signOut();
        setShowPopup(false);
    };

    return (
        <AuthContext.Provider value={{ token, loading, user, signIn, signOut }}>
            {children}
            {showPopup && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white p-6 rounded shadow-md text-center">
                        <h2 className="text-xl font-semibold mb-4">{error}</h2>
                        <button
                            onClick={handleSignOut}
                            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                        >
                            {translations.errors.accessTokenExpiryButton}
                        </button>
                    </div>
                </div>
            )}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
