interface IConfig {
    API_BASE_URL: string;
}

export class Config implements IConfig {
    public readonly API_BASE_URL: string;

    constructor() {
        this.API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

        this.validateConfig();
    }

    private validateConfig() {
        if (!this.API_BASE_URL) {
            throw new Error('Missing environment variable: REACT_APP_API_BASE_URL');
        }
        // Add any other validation logic as needed
    }
}

export const config = new Config();
