import React, { useEffect, useState } from 'react';
import { useI18n } from "../../i18n/I18nContext";
import { Job } from "../../types/Job";
import JobCard from "../../components/JobCard";
import { useNavigate, useLocation } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import { useAuth } from '../../auth/AuthContext';
import JobsSkeletonCard from "../../components/Skeletons/JobsSkeletonCard";
import { PlusCircleIcon, ArrowPathIcon } from '@heroicons/react/24/solid';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';

const Jobs: React.FC = () => {
    const { translations } = useI18n();
    const { user, loading: authLoading } = useAuth();
    const [jobs, setJobs] = useState<Job[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalJobs, setTotalJobs] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const jobsPerPage = 10;
    const navigate = useNavigate();
    const location = useLocation();
    const isAdmin = user?.permission === "admin";

    useEffect(() => {
        fetchJobs(currentPage);

        if (location.state?.success) {
            toast.success('Job initiated successfully');
            window.history.replaceState({}, document.title);
            navigate(location.pathname, { replace: true });
        }
    }, [user, authLoading, location.pathname, location.state?.success, navigate, currentPage]);

    const fetchJobs = async (page: number) => {
        if (!user || authLoading) return;
        setLoading(true);

        try {
            const jobsResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/job/get-all-jobs/${user.id}?page=${page}&page_size=${jobsPerPage}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!jobsResponse.ok) {
                throw new Error('Failed to fetch jobs');
            }

            const jobsData = await jobsResponse.json();
            const sortedJobs = sortJobs(jobsData.jobs);
            setJobs(sortedJobs);
            setTotalJobs(jobsData.total_jobs);
            setTotalPages(jobsData.total_pages);
        } catch (err) {
            console.error('Error fetching jobs:', err);
            setError('Error fetching jobs');
        } finally {
            setLoading(false);
        }
    };

    const sortJobs = (jobs: Job[]) => {
        const statusPriority = ['initiated', 'checkpoint_one', 'checkpoint_two', 'complete'];

        return jobs.sort((a, b) => {
            const statusDiff = statusPriority.indexOf(a.status) - statusPriority.indexOf(b.status);
            if (statusDiff !== 0) {
                return statusDiff;
            } else {
                return new Date(b.created_at).getTime() - new Date(a.created_at).getTime(); // Sort by creation time (newest first)
            }
        });
    };

    const handleCreateNewJob = () => {
        navigate('/jobs/init');
    };

    const handleReloadJobs = () => {
        fetchJobs(currentPage);
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };
    const handleDeleteJob = (jobId: string) => {
        setJobs(prevJobs => prevJobs.filter(job => job.id !== jobId));
    };

    if (loading || authLoading) {
        return (
            <div className="bg-white shadow-md rounded-lg p-6">
                <ToastContainer position="top-right" autoClose={5000} />
                <h2 className="text-xl font-semibold text-gray-800">{translations.jobs.title}</h2>
                <div className="mt-6">
                    {Array.from({ length: 5 }).map((_, index) => (
                        <JobsSkeletonCard key={index} />
                    ))}
                </div>
            </div>
        );
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div className="bg-white shadow-md rounded-lg p-6">
            <ToastContainer position="top-right" autoClose={1000} />
            <div className="md:flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold text-gray-800">{translations.jobs.title}</h2>
                <div className="md:flex items-center gap-2">
                    {isAdmin && <button
                        onClick={() => {
                            navigate('/jobs/view-all-user-jobs')
                        }}
                        className="my-2 bg-blue-700 text-white px-4 py-2 rounded hover:bg-blue-600 transition-transform transform hover:scale-105 flex items-center gap-2"
                    >
                        {translations.jobs.viewAllUserJobs}
                    </button>}
                    <div className="flex gap-4 justify-between">
                    <button
                        onClick={handleCreateNewJob}
                        className="bg-primary text-white px-4 py-2 rounded hover:bg-blue-600 transition-transform transform hover:scale-105 flex items-center gap-2"
                    >
                        <PlusCircleIcon className="h-5 w-5" />
                        {translations.jobs.button}
                    </button>
                    <button
                        onClick={handleReloadJobs}
                        className="bg-gray-200 text-gray-600 px-4 py-2 rounded hover:bg-gray-300 transition-transform transform hover:scale-105 flex items-center gap-2"
                        disabled={loading}
                    >
                        {loading ? (
                            <ArrowPathIcon className="h-5 w-5 animate-spin" />
                        ) : (
                            <ArrowPathIcon className="h-5 w-5" />
                        )}
                    </button>
                    </div>
                </div>
            </div>
            <input
                type="text"
                value={searchTerm}
                onChange={handleSearch}
                placeholder={translations.jobs.searchPlaceholder}
                className="mb-4 p-2 w-full border rounded"
            />
            {totalJobs === 0 ? (
                <div className="text-center mt-8 text-gray-600">
                    <ExclamationCircleIcon className="h-16 w-16 mx-auto text-gray-400" />
                    <p className="mt-4">{translations.jobs.noJobsMessage}</p>
                </div>
            ) : (
                <ul className="mt-4">
                    {jobs
                        .filter(job => job.job_name.toLowerCase().includes(searchTerm.toLowerCase()))
                        .map(job => (
                            <JobCard key={job.id} job={job} onDelete={handleDeleteJob} />
                        ))}
                </ul>
            )}
            <div className="flex justify-center mt-4">
                {currentPage > 1 && (
                    <button
                        onClick={handlePreviousPage}
                        className="mx-1 px-3 py-1 rounded bg-gray-200 hover:bg-gray-300"
                    >
                        {translations.jobs.previous}
                    </button>
                )}
                {Array.from({ length: totalPages }).map((_, index) => (
                    <button
                        key={index}
                        onClick={() => handlePageChange(index + 1)}
                        className={`mx-1 px-3 py-1 rounded ${index + 1 === currentPage ? 'bg-primary text-white' : 'bg-gray-200'}`}
                    >
                        {index + 1}
                    </button>
                ))}
                {currentPage < totalPages && (
                    <button
                        onClick={handleNextPage}
                        className="mx-1 px-3 py-1 rounded bg-gray-200 hover:bg-gray-300"
                    >
                        {translations.jobs.next}
                    </button>
                )}
            </div>
        </div>
    );
};

export default Jobs;
