import React from 'react';
import {useI18n} from "../../i18n/I18nContext";


const LanguageToggle: React.FC = () => {
    const { language, setLanguage, translations } = useI18n();

    const toggleLanguage = () => {
        const newLanguage = language === 'en' ? 'zh' : 'en';
        setLanguage(newLanguage);
        localStorage.setItem('language_preference', newLanguage);
    };

    return (
        <button
            onClick={toggleLanguage}
            className="fixed bottom-4 left-4 bg-blue-500 text-white px-4 py-2 rounded"
        >
            {translations.toggle_button.language}
        </button>
    );
};

export default LanguageToggle;
