import React, { createContext, useState, useContext, ReactNode } from 'react';
import en from './en.json';
import zh from './zh.json';
import { Translations } from './types';

type Language = 'en' | 'zh';

interface I18nContextProps {
    language: Language;
    setLanguage: (language: Language) => void;
    translations: Translations;
}

const I18nContext = createContext<I18nContextProps | undefined>(undefined);

export const I18nProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [language, setLanguage] = useState<Language>(() => {
        const savedLanguage = localStorage.getItem('language_preference');
        return (savedLanguage === 'zh' || savedLanguage === 'en') ? savedLanguage as Language : 'en';
    });

    const translations: Translations = language === 'en' ? en : zh;

    return (
        <I18nContext.Provider value={{ language, setLanguage, translations }}>
            {children}
        </I18nContext.Provider>
    );
};

export const useI18n = () => {
    const context = useContext(I18nContext);
    if (!context) {
        throw new Error('useI18n must be used within an I18nProvider');
    }
    return context;
};
